import { Button, Input, Modal, Select } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CustomersDropdown from '../CustomersDropdown';
import PlatformsDropdown from '../PlatformsDropdown';
import * as XLSX from 'xlsx'
import { createWhitelist, updateWhitelist } from '../../redux/apis/whitelistApi';
import { toast } from 'react-toastify';

const EditCreateCampaign = ({ state, open, setOpen, channel }) => {
    const dispatch = useDispatch()
    const [insertionType, setInsertionType] = useState(channel ? 'single' : 'bulk')
    const [toBeSentData, setToBeSentData] = useState(null)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            channel_url: channel?.channel_url || "",
            channel_name: channel?.channel_name || "",
            channel_id: channel?.channel_id || "",
            platform: channel?.platform,
            customer: channel?.customer?.id,
            is_active: channel?.is_active
        },
        validationSchema: Yup.object({
            channel_url: insertionType === 'single' ? Yup.string().required(`Please Enter Channel URL`) : Yup.string().nullable(),
            channel_name: insertionType === 'single' ? Yup.string().required(`Please Enter Channel Name`) : Yup.string().nullable(),
            channel_id: insertionType === 'single' ? Yup.string().required(`Please Enter Channel ID`) : Yup.string().nullable(),
            platform: insertionType === 'single' ? Yup.string().required(`Please Select Platform`) : Yup.string().nullable(),
            customer: insertionType === 'single' ? Yup.string().required(`Please Select Customer`) : Yup.string().nullable(),
        }),
        onSubmit: async (values) => {
            if (state === "edit") {
                dispatch(updateWhitelist({ id: channel.id, data: values }))
                toast.info(`Updatting Whitelist Please Wait!`)
            }
            if (state === "create") {
                if (insertionType === "bulk") {
                    if (!toBeSentData || toBeSentData.length === 0) {
                        toast.error(`Please Select a File That Contains Channels`)
                        return
                    }
                    dispatch(createWhitelist(toBeSentData))
                    toast.info(`Creating Whitelist Please Wait!`)
                } else {
                    
                    dispatch(createWhitelist([values]))
                    toast.info(`Creating Whitelist Please Wait!`)
                }
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }
        }
    });
    const handleFileChange = async (e) => {
        if (!e.target.files[0]) {
            setToBeSentData(null)
            return
        }
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { cellDates: true });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];


        let jsonData = XLSX.utils.sheet_to_json(worksheet);
        jsonData = jsonData.map((item) => {
            return {
                channel_url: item["Channel URL"],
                channel_name: item["Channel Name"],
                channel_id: item["Channel ID"],
                platform: item["Platform ID"],
                customer: item["Customer ID"]
            }
        })
        console.log(jsonData)
        setToBeSentData(jsonData)
    }


    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Edit Channel" : "Create Channel"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}

        >
            <div className=" mb-3">
                <label className="" >Insertion Type</label>
                <br />
                <Select
                    style={{
                        width: '100%',
                    }}
                    placeholder="Please select"
                    value={insertionType}
                    disabled={channel ? true : false}
                    onChange={(e) => setInsertionType(e)}
                    options={[
                        {
                            label: "Single",
                            value: "single"
                        },
                        {
                            label: "Bulk",
                            value: "bulk"
                        },
                    ]}
                />
            </div>
            {
                insertionType === "single" ?
                    <>
                        <div className=" mb-3">
                            <label className="" >Channel URL</label>
                            <Input
                                type="text"
                                name='channel_url'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.channel_url}
                            />
                            {validation.touched.channel_url && validation.errors.channel_url ? (
                                <p className='text-danger'>{validation.errors.channel_url}</p>
                            ) : null}
                        </div>
                        <div className=" mb-3">
                            <label className="" >Channel Name</label>
                            <Input
                                type="text"
                                name='channel_name'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.channel_name}
                            />
                            {validation.touched.channel_name && validation.errors.channel_name ? (
                                <p className='text-danger'>{validation.errors.channel_name}</p>
                            ) : null}
                        </div>

                        <div className=" mb-3">
                            <label className="" >Channel ID</label>
                            <Input
                                type="text"
                                name='channel_id'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.channel_id}
                            />
                            {validation.touched.channel_id && validation.errors.channel_id ? (
                                <p className='text-danger'>{validation.errors.channel_id}</p>
                            ) : null}
                        </div>
                        <div>
                            <PlatformsDropdown
                                onChange={e => validation.setFieldValue("platform", e)}
                                value={validation.values.platform}
                                onBlur={validation.handleBlur}
                                multi={false}
                            />
                            {validation?.touched?.platform && validation?.errors?.platform ? (
                                <p className='text-danger'>{validation?.errors?.platform}</p>
                            ) : null}
                        </div>
                        <div>
                            <CustomersDropdown
                                onChange={e => { validation.setFieldValue("customer", e) }}
                                value={validation.values.customer}
                                onBlur={validation.handleBlur}
                            />
                            {validation.touched.customer && validation.errors.customer ? (
                                <p className='text-danger'>{validation.errors.customer}</p>
                            ) : null}
                        </div>
                        <div className=" mb-3">
                            <label className="" >Status</label>
                            <br />
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                value={validation.values.is_active}
                                onChange={(e) => validation.setFieldValue("is_active", e)}
                                options={[
                                    {
                                        label: "Active",
                                        value: true
                                    },
                                    {
                                        label: "Deactivated",
                                        value: false
                                    },
                                ]}
                            />
                        </div>

                    </>
                    :
                    <div className=" mb-3">
                        <label className="" >Channels</label>
                        <br />
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept=".xlsx, .xls"
                            name='urls'
                            id='urls'
                        />

                    </div>
            }

        </Modal>
    )
}

export default EditCreateCampaign