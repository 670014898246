import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { listPlatforms } from '../../redux/apis/platformApi';
import LinksInsertion from '../../components/LinkInsertion/LinksInsertion';
import TextAreaInsertion from '../../components/LinkInsertion/TextAreaInsertion';
import Loader from '../pages/loader';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearError, clearPlatform } from '../../redux/slices/platformSlice';
import { toast } from 'react-toastify';

function ContentIDLinkInsertionInstagram() {
	const dispatch = useDispatch()
	const { platforms, isLoading, error } = useSelector(state => state.platform)

	useEffect(() => {
		
			dispatch(listPlatforms({
				platform_name__icontains: 'instagram',
				page: '*',
				ordering: '-id',
				fields: ['id'].join(',')
			}))
			return function cleanUp() {
				dispatch(clearPlatform());  // Dispatch the action to clear platform data
			};
	}, [])


	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error)
		dispatch(clearError())
    },[error, isLoading])

	if (!can("add_videourl")) {
        return <NoPermission />
    }
	
	return (
		<div className='flex flex-col gap-8'>
			<Loader isLoading={isLoading}/>
			{platforms ? <>
				<LinksInsertion
					platform_name="Instagram"
					platformId={platforms[0]?.id}
					matchText={[/instagram\.com/]}
					isContentID={true}
				/>
				<TextAreaInsertion
					platform_name="Instagram"
					platformId={platforms[0]?.id}
					matchText={[/instagram\.com/]}
					isContentID={true}
				/>
			</> : null}
		</div>
	)
}

export default ContentIDLinkInsertionInstagram;